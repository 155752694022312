<template>
  <div>
    <div>
      <div class="flex search-header">
        <div class="flex search-header__container">
          <div class="search-header__image-container" @click="onBack">
            <img src="../../../static/icon/back.png" class="width_height_hundred"/>
          </div>
          <div class="flex search-header__middle">
            <svg t="1720604717925" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5230" width="25" height="25">
              <path d="M451.946667 768A316.266667 316.266667 0 1 1 768 452.053333 316.586667 316.586667 0 0 1 451.946667 768z m0-589.76A273.6 273.6 0 1 0 725.333333 452.053333a273.92 273.92 0 0 0-273.386666-273.6z" fill="#252525" p-id="5231"></path>
              <path d="M644.48 416a21.333333 21.333333 0 0 1-19.2-11.946667 275.413333 275.413333 0 0 0-132.16-128.64 21.333333 21.333333 0 0 1 17.706667-38.826666 318.08 318.08 0 0 1 152.746666 148.693333 21.333333 21.333333 0 0 1-9.706666 28.586667 21.333333 21.333333 0 0 1-9.386667 2.133333z" fill="#252525" p-id="5232"></path>
              <path d="M816.426667 832a21.333333 21.333333 0 0 1-15.04-6.293333l-112.106667-112a21.333333 21.333333 0 0 1 30.186667-30.186667L832 795.626667a21.333333 21.333333 0 0 1 0 30.08 21.333333 21.333333 0 0 1-15.573333 6.293333z" fill="#252525" p-id="5233"></path>
            </svg>
            <input @input="onInput" @focus="onFocus" @blur="onBlur" v-model="search" :placeholder="searchGame"/>
            <svg @click="cleanSearch" v-show="showClean" style="cursor: pointer;" t="1722236303752" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4296" width="20" height="20">
              <path d="M874.057143 149.942857a512 512 0 1 0 0 724.114286 512 512 0 0 0 0-724.114286zM687.908571 636.16a25.782857 25.782857 0 0 1 0 36.571429l-15.542857 15.542857a25.782857 25.782857 0 0 1-36.571428 0L512 563.748571l-124.16 124.16a25.782857 25.782857 0 0 1-36.571429 0l-15.542857-15.542857a25.782857 25.782857 0 0 1 0-36.571428L460.251429 512l-124.16-124.16a25.782857 25.782857 0 0 1 0-36.571429l15.542857-15.542857a25.782857 25.782857 0 0 1 36.571428 0L512 460.251429l124.16-124.16a25.782857 25.782857 0 0 1 36.571429 0l15.542857 15.542857a25.782857 25.782857 0 0 1 0 36.571428L563.748571 512z" fill="#cdcdcd" p-id="4297"></path>
            </svg>
          </div>
          <div class="search-header__floor" v-show="showBtn">
            <div @click="onSearch" class="search-header__btn width_height_hundred">搜索</div>
          </div>
        </div>
      </div>
      <div class="search-main">
        <div v-show="!showResult">
          <div v-show="showHistory">
            <div>
              <div class="flex search-main__history">
                <div class="gradient-text search-main__title">历史记录</div>
                <div style="color: #757575;font-size: 14px;">
                  <van-icon name="delete-o" color="#757575"/>
                  <span style="cursor: pointer;" @click="cleanHistory">清空</span>
                </div>
              </div>
              <div class="search-main_history-record">
                <div @click="onClickHistory(item)" class="search-main__history-record-item" v-for="(item,index) in historyData" :key="index">{{ item }}</div>
              </div>
            </div>
          </div>
          <div style="padding-top: 20px;" v-if="hotSearch.length !== 0">
            <div>
              <div class="gradient-text search-main__title">热搜游戏</div>
            </div>
            <div class="flex search-main_hot-container">
              <div class="search-main__hot-game" @click="onClickHistory(item.gameName)" v-for="(item,index) in hotSearch" :key="index">
                <div style="font-size: 14px;width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                  {{ item.gameName }}
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div style="padding: 10px 10px 0;" v-show="showResult">
          <van-list
              ref="list"
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              :offset="150"
              :immediate-check="false"
              @load="onLoad"
          >
            <div class="sidebar-right-game-item" v-for="(item,index) in gameData" :key="index" @click="toGameDetails(item.gameId)">
              <div>
                <img :src="item.icon" width="60" height="60" alt=""/>
              </div>
              <div>
                <div class="game-title">{{ item.gameName }}</div>
                <div v-if="!!item.tag">
                  <span class="sidebar-right-game-item-tag" v-for="(tag,index) in item.tag.split(',')" :key="index">{{ tag }}</span>
                </div>
              </div>
            </div>
          </van-list>
        </div>

        <loading-animation :show-overlay="showOverlay"/>
<!--        <div class="loading-mask" v-show="showOverlay">-->
<!--          <div class="wrapper" @click.stop>-->
<!--           -->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {getHotSearch, queryGame} from "@/api/request";
import LoadingAnimation from "@/components/loadingAnimation.vue";

export default {
  name: "search",
  components: {LoadingAnimation},
  data(){
    return {
      search: '',
      searchHistory: "searchHistory",
      showHistory: false,
      historyData: [],
      hotSearch: [],
      showBtn: true,
      loading: false,
      finished: false,
      gameData: [],
      pageNo: 1,
      showOverlay: false,
      showResult: false,
      showClean: false,
      searchGame: '',
    }
  },
  mounted() {
    this.checkHistory();
    this.getHotSearch();
    if(this.$route.params.pathMatch){
      this.searchGame = this.$route.params.pathMatch;
    }
  },
  methods: {
    onSearch(){
      this.showHistory = true;
      this.showOverlay = true;

      this.gameData = [];
      this.pageNo = 1;
      this.setHistory();
      this.loadData();
    },
    loadData(){
      let params = {
        pageNo: this.pageNo,
        params: {
          gameName: this.search
        }
      }
      queryGame(params).then(res => {
        let _data = res.data.data.list;
        if(_data.length !== 0){
          this.pageNo++;
          this.loading = false;
          this.gameData = [...this.gameData,..._data];
          this.$refs.list.check();
          this.showResult = true;
          this.showOverlay = false;
        }
        if(_data.length === 0){
          this.finished = true;
        }
      })
    },
    onLoad(){
      this.loadData();
    },
    getHotSearch(){
      getHotSearch().then(res => {
        this.hotSearch = res.data.data;
      })
    },
    checkHistory(){
      const history = JSON.parse(localStorage.getItem(this.searchHistory));
      this.showHistory = !!history;
      this.historyData = history;
    },
    cleanHistory(){
      localStorage.removeItem(this.searchHistory);
      this.historyData= [];
      this.showHistory = false;
    },
    cleanSearch(){
      this.search = '';
      this.showResult = false;
      this.showClean = false;
    },
    setHistory(){
      let history = JSON.parse(localStorage.getItem(this.searchHistory));
      if(!history){
        history = [];
      }
      !this.search && (this.search = this.searchGame);
      if (history.filter(f => f === this.search).length === 0 && this.search) {
        history.push(this.search);
        localStorage.setItem(this.searchHistory, JSON.stringify(history));
      }
      this.checkHistory();
    },
    onClickHistory(item){
      !!item && (this.search = item);
      this.setHistory();
      this.onSearch();
    },
    toGameDetails(gameId){
      this.$router.push(`/game/details/${gameId}`);
    },
    onBack(){
      this.$router.back();
    },
    onInput(){
      if (this.search.length === 0) {
        this.showResult = false;
      }
    },
    onFocus(){
      this.search.length !== 0 && (this.showClean = true);
    },
    onBlur(){
      this.search.length === 0 && (this.showClean = false);
    },
  }
}
</script>

<style scoped>
.flex {
  display: flex;
}
.width_height_hundred {
  width: 100%;
  height: 100%;
}
.search-header {
  height: 60px;
  position: relative;
  top: 0;
  
  align-content: center;
}
.search-header__container {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.search-header__image-container {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 99px;
}
.search-header__image-container:hover {
  background-color: #f0f2f4;
}

.search-header__middle {
  flex: 1;
  align-items: center;
  height: 40px;
  background-color: #f0f2f4;
  border-radius: 99px;
  padding: 0 15px;
}
.search-header__middle input {
  border: 0;
  background-color: #f0f2f4;
  width: 100%;
  margin-left: 10px;
  font-weight: 700;
}
.search-header__floor {
  width: 80px;
  height: 40px;
  line-height: 40px;
  margin-left: 10px;
}
.search-header__btn {
  text-align: center;
  background-color: #9ad1aa;
  border-radius: 99px;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.search-main {
  margin-top: 15px;
  position: relative;
}
.search-main__history {
  justify-content: space-between;
  align-items: center;
}
.gradient-text {
  background: linear-gradient(to right, #9BE15D, #00E3AE); /*设置渐变的方向从左到右*/
  -webkit-background-clip: text;/*将设置的背景颜色限制在文字中*/
  -webkit-text-fill-color: transparent;/*给文字设置成透明*/
}
.search-main__title {
  font-weight: 700;
}
.search-main_history-record {
  display: flex;
  flex-direction: row;
  row-gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
  
  max-height: 160px;
  overflow: hidden;
  overflow-y: auto;
  cursor: pointer;
}
.search-main__history-record-item {
  background-color:#f0f2f4;
  border-radius: 99px;
  padding: 5px 10px;
  color: #757875;
}
.search-main_hot-container {
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  margin-top: 10px;
}
.search-main__hot-game {
  width: calc(50% - 30px);
  padding: 10px;
  background-color: #f0f2f4;
  border-radius: 8px;
  cursor: pointer;
}

/deep/ .my-overlay {
  height: calc(100% - 60px);
  z-index: 400;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.loading-mask {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fafcfd;
}

@media only screen and (max-width: 499px) {
  .search-header__image-container {
    width: 25px;
    height: 25px;
  }
  .search-header__middle {
    height: 30px;
  }
  .search-header__middle input {
    font-size: 13px;
  }
  .search-header__floor {
    width: 60px;
    height: 30px;
    line-height: 30px;
  }
  
  .search-main__title {
    font-size: 15px;
  }
}

</style>