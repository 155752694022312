import { render, staticRenderFns } from "./gameDetails.vue?vue&type=template&id=3df2eb76&scoped=true"
import script from "./gameDetails.vue?vue&type=script&lang=js"
export * from "./gameDetails.vue?vue&type=script&lang=js"
import style0 from "../../../static/css/game-detail.css?vue&type=style&index=0&id=3df2eb76&prod&scoped=true&lang=css&external"
import style1 from "./gameDetails.vue?vue&type=style&index=1&id=3df2eb76&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3df2eb76",
  null
  
)

export default component.exports