<template>
  <div>
    <div>
      <div>
        <van-nav-bar title="关于" left-arrow left-text="返回" z-index="99" placeholder safe-area-inset-top @click-left="onClickLeft" />
      </div>
      <div>
        <div class="setting-view">
          <router-link :to="c.path" class="base-flex-row" style="color: #252525;" v-for="c in itemList" :key="c.label">
            <div style="font-size: 14px;">{{ c.label }}</div>
            <div>
              <van-icon name="arrow" size="14" color="#75787b"/>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about",
  data(){
    return {
      itemList: [
        {label: '关于我们', path: "/about"},
        {label: '用户协议', path: '/user/agreement'},
        {label: '隐私政策', path: "/privacy"},
      ]
    }
  },
  methods: {
    onClickLeft() {
      this.$router.push('/setting');
    },
  }
}
</script>

<style scoped src="../../../../static/css/setting.css"></style>
<style scoped>
/deep/ .van-nav-bar__left{
  padding-left: 8px;
}
.setting-view {
  width: 90%;
}
</style>