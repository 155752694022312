<template>
  <div>
    <div>
      <div class="top-title">
        <div class="middle-size" style="display: flex;flex-direction: row;column-gap: 10px;position: fixed;background-color: #fafcfd;padding: 10px 0 10px;">
          <div class="game-detail-back">
            <img src="../../../static/icon/back.png" @click="$router.back()">
          </div>
          <div>
            {{ contentData.contentName }}
          </div>
        </div>
      </div>
      <div>
        <div>
          <div class="content-des-text">{{ contentData.contentDes }}</div>
          <div class="content-time-text">发布于：{{ contentData.addTime }}</div>
        </div>
      </div>
      <div class="content-text__container" v-html="contentData.contentText"></div>
    </div>
  </div>
</template>

<script>
import {getContentDetail} from "@/api/request";

export default {
  name: "contentDetail",
  data(){
    return {
      contentId: '',
      contentData: {},
    }
  },
  mounted() {
    this.contentId = this.$route.params.pathMatch;
    this.loadContent();
  },
  methods: {
    loadContent(){
      getContentDetail(this.contentId).then(res => {
        if(res.data.success){
          this.contentData = res.data.data;
        }
      })
    },
  }
}
</script>

<style scoped src="../../../static/css/game-detail.css" />
<style scoped src="../../../static/css/gift.css" />
<style scoped>
.content-text__container {
  margin: 15px 0;
}
.content-time-text {
  margin-top: 5px;
  font-size: 12px;
  color: #666666;
}
.content-des-text {
  font-size: 18px;
  padding: 5px 0;
  color: #252525;
  font-weight: 700;
}
</style>