<template>
  <div>
    <div ref="scrollable" @mousedown="startMove" @mouseup="endMove" @mouseleave="endMove" @mousemove="change" class="swiper-container">
      <video ref="video" v-if="!!videoUrl" :src="videoUrl" style="width: 100%;height: 100%;border-radius: 5px;" muted :controls="true" :autoplay="false" />
      <div style="white-space: nowrap;overflow: hidden;display: inline-block" v-if="imageList.length !== 0">
        <div v-for="(imageUrl,index) in imageList" style="margin-left: 15px;display: inline-block">
          <img :id="'img' + index" :style="isMoving ? 'pointer-events: none' : ''" 
                style="width: 11.3rem; height: 17.5rem;border-radius: 5px;"
                :src="imageUrl"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "slideshow",
  props: {
    videoUrl: {
      type: String,
      default: ''
    },
    imageList: {
      type: Array,
      default: () => []
    },
  },
  data(){
    return {
      isMoving: false,
    }
  },
  mounted() {
  },
  methods: {
    //获取随机16进制颜色
    getRandomColor(){
      let color = '#';
      for(let i = 0; i < 6; i++){
        color += Math.floor(Math.random() * 10);
      }
      return color;
    },
    startMove(e) {
      this.isMouseDown = true;
      this.startX = e.pageX - this.$refs.scrollable.offsetLeft;
      this.scrollLeft = this.$refs.scrollable.scrollLeft;
    },
    endMove(e) {
      this.isMouseDown  = false;
      this.isMoving  = false;
    },
    change(e) {
      if (!this.isMouseDown) return;
      e.preventDefault();
      let x = e.pageX - this.$refs.scrollable.offsetLeft
      let walk = (x - this.startX) * 1.5; // 调整滚动速度
      if (walk !== 0) {
        this.isMoving  = true;
        this.$refs.scrollable.scrollLeft = this.scrollLeft - walk;
      }
    },
  }
}
</script>

<style scoped>
.swiper-container::-webkit-scrollbar {
  display: none;
}
.swiper-container {
  /*display: flex;*/
  /*flex-direction: row;*/
  cursor: pointer;
  width: 500px;
  height: 100%;
  overflow: hidden; 
  white-space: nowrap;
  position: relative;
  font-size: 0;
  -webkit-overflow-scrolling: touch;
}
</style>