<template>
  <div>
    <div style="min-height: 1180px;user-select: none;">
      <div style="height: 40px;">
        <div style="display: flex;flex-direction: row;column-gap: 10px;position: fixed;background-color: #fafcfd;z-index: 999">
          <div class="game-detail-back">
            <img src="../../../static/icon/back.png" @click="$router.back()">
          </div>
          <div>
            <div class="top-nav" style="position: relative;width: 460px">
              <div v-for="(item,index) in navList" class="top-nav-text" :class="{'top-nav-text-active': curIndex === index}" @click="click(index)">{{ item.label }}</div>
              <span class="top-nav-slide-line" data-line="true" data-full-width="1" style="width: 50px;pointer-events: auto;" :style="{transform: underlineX}"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="base-flex-column" style="padding: 20px 0">
        <div>
          <slideshow :video-url="videoUrl"
          :image-list="imageData" />
        </div>
        <div class="base-grid-row">
          <div>
            <img :src="gameDetailData.gameIcon" width="100" height="100" alt=""/>
          </div>
          <div>
            <div class="game-title" style="font-size: 22px;">{{ gameDetailData.gameName }}</div>
            <div v-if="!!gameDetailData.tag">
              <span v-for="(tag,index) in gameDetailData.tag.split(',')" :key="index" class="sidebar-right-game-item-tag">{{ tag }}</span>
            </div>
          </div>
          <div>
            <div class="game-discount">
              <span>{{ gameDetailData.discount }}</span>
              <span>折</span>
            </div>
          </div>
        </div>
        <div class="base-flex-row" style="column-gap: 15px;text-align: center;">
<!--          <div class="download-button" style="background-color: rgba(180,222,190,0.5);color: #9ad1aa;">在线玩</div>-->
          <div v-if="gameDetailData.playUrl" @click="toPlay" class="download-button" style="flex: 1;color: #9ad1aa;background-color: #d7eddd">在线玩</div>
          <div @click="toDownload" class="download-button" style="flex: 1">
            <span>下载</span>
            <span style="font-size: 14px;" v-if="gameDetailData.gameSize">( {{ gameDetailData.gameSize}} )</span>
          </div>
        </div>

        <div class="game-detail-info-view">
          <div class="game-detail-info-view-title">
            游戏介绍
          </div>
          <div>
            <div style="margin-top: 10px;">
              {{ gameDetailData.gameDesc }}
            </div>
          </div>
        </div>
        
        <div class="game-detail-info-view" v-if="false">
          <div class="game-detail-info-view-title">
            游戏礼包
          </div>
          <div v-if="true">
            <div class="base-flex-row game-detail-info-server game-detail-info-content-view" v-for="item in 4">
              <div>
                <div class="base-hidde-text" style="width: 340px;">测试礼包</div>
                <div class="sidebar-right-game-item-tag base-hidde-text" style="width:340px;">
                  奖励1 * 1、奖励2 * 1、奖励3 * 1、奖励4 * 1、奖励1 * 1、奖励2 * 1、奖励3 * 1、奖励4 * 1\奖励1 * 1、奖励2 * 1、奖励3 * 1、奖励4 * 1
                </div>
              </div>
              <div>
<!--                <button style="border: 0;border-radius: 99px;padding:5px 15px;background-color: #9ad1aa;color: #f0f2f4;cursor: pointer;">领取</button>-->
                <svg t="1720073482704" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                  <path d="M457.116507 270.770913" fill="#262635"></path>
                  <path d="M374.52751 822.6072c-6.500041 0-12.997012-2.478448-17.956977-7.437389-9.920954-9.91686-9.924024-25.999141-0.00614-35.920094l271.304056-271.394107c0.202615-0.201591 0.607844-0.603751 0.60989-1.397836 0.002047-0.589424-0.212848-1.104147-0.63752-1.529843L356.596116 233.806024c-9.920954-9.91686-9.925047-25.999141-0.008186-35.920094 9.915837-9.920954 25.999141-9.92607 35.920094-0.008186l271.279497 271.1567c10.04068 10.07445 15.528672 23.401989 15.489786 37.561477-0.037862 14.100136-5.561669 27.327392-15.552208 37.243229L392.489604 815.164694C387.530662 820.126706 381.028575 822.6072 374.52751 822.6072z" />
                </svg>
              </div>
            </div>
            <div class="look-more" @click="toGiftPage">查看更多</div>
          </div>
          <div class="no-text" v-else>
            暂无游戏礼包
          </div>
        </div>
        
        <div class="game-detail-info-view" v-if="false">
          <div class="game-detail-info-view-title">
            开服信息
          </div>
          <div v-if="true">
            <div class="base-flex-row game-detail-info-server">
              <div v-for="item in 6" class="game-detail-info-server-item">
                <div style="font-size: 18px;">07-04 10:00</div>
                <div style="font-size: 14px;color: #75787b;">天使999服</div>
              </div>
            </div>
          </div>
          <div class="no-text" v-else>
            暂无开服信息
          </div>
        </div>
        <div class="game-detail-info-view" v-if="false">
          <div class="game-detail-info-view-title">
            游戏攻略
          </div>
          <div v-if="true">
            <div class="base-flex-row game-detail-info-server game-detail-info-content-view" v-for="item in 4">
              <div>
                <div class="base-hidde-text" style="width: 340px;">测试攻略</div>
                <div class="sidebar-right-game-item-tag base-hidde-text" style="width:340px;">
                  测试内容测试内容测试内容测试内容
                </div>
              </div>
              <div>
                <svg t="1720073482704" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                  <path d="M457.116507 270.770913" fill="#262635"></path>
                  <path d="M374.52751 822.6072c-6.500041 0-12.997012-2.478448-17.956977-7.437389-9.920954-9.91686-9.924024-25.999141-0.00614-35.920094l271.304056-271.394107c0.202615-0.201591 0.607844-0.603751 0.60989-1.397836 0.002047-0.589424-0.212848-1.104147-0.63752-1.529843L356.596116 233.806024c-9.920954-9.91686-9.925047-25.999141-0.008186-35.920094 9.915837-9.920954 25.999141-9.92607 35.920094-0.008186l271.279497 271.1567c10.04068 10.07445 15.528672 23.401989 15.489786 37.561477-0.037862 14.100136-5.561669 27.327392-15.552208 37.243229L392.489604 815.164694C387.530662 820.126706 381.028575 822.6072 374.52751 822.6072z" />
                </svg>
              </div>
            </div>
            <div class="look-more" @click="totoGameStrategyPage">查看更多</div>
          </div>
          <div class="no-text" v-else>
            暂无游戏攻略
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getGameDetail} from "@/api/request";
import MySwiper from "@/components/MySwiper.vue";
import Slideshow from "@/components/slideshow.vue";

export default {
  name: "gameDetail",
  components: {MySwiper, Slideshow},
  data(){
    return {
      curIndex: 0,
      underlineX: "translate3d(calc(-50% + 30px), 0px, 0px)",
      navList: [
        {label: "详情"}
      ],
      gameDetailData: [],
      imageData: [],
      videoUrl: '',
      gameId: ''
    }
  },
  mounted() {
    this.gameId = this.$route.params.pathMatch;
    this.loadGame();
  },
  methods: {
    loadGame(){
      getGameDetail(this.gameId).then(res => {
        if(res.data.success){
          this.gameDetailData = res.data.data;
          if(this.gameDetailData.introVideo.url){
            this.videoUrl = this.gameDetailData.introVideo.url;
          }
          if(this.gameDetailData.introImageList.length !== 0){
            this.gameDetailData.introImageList.forEach(f => {
              this.imageData.push(f.url);
            })
          }
        }
      })
    },
    click(index){
      this.curIndex = index;
      let defaultGap = 30;
      let moveGap = 65;
      let style = "translate3d(calc(-50% + {1}px), 0px, 0px)";
      if(index === 0){
        this.underlineX = style.replace("{1}",defaultGap + "");
      }else {
        let gap = moveGap * index + defaultGap;
        this.underlineX = style.replace("{1}",gap + "");
      }
    },
    toPlay(){
      window.open(this.gameDetailData.playUrl,'_blank');
    },
    toDownload(){
      location.href = this.gameDetailData.downloadUrl;
    },
    toGiftPage(){
      this.$router.push('/gift');
    },
    totoGameStrategyPage(){
      this.$router.push('/strategy');
    }
  }
}
</script>


<style scoped src="../../../static/css/game-detail.css">
</style>