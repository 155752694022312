<template>
  <div>
    <div ref="mask" class="swiper-container" @mousedown="mouseDownHandle">
      <div v-if="videoSrc">
        <video :src="videoSrc"
               :poster="poster"
               controls muted disablePictureInPicture
               controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
               class="swiper-video-item" />
      </div>
      <div class="swiper-image-view" v-if="imageList.length !== 0">
        <div v-for="item in imageList">
          <img class="swiper-image" :src="item" style=""/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MySwiper",
  props: {
    videoSrc: {
      type: String,
      default: ''
    },
    poster: {
      type: String,
      default: ''
    },
    container: {},
    imageList: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
  },
  methods: {
    mouseDownHandle(e){
      const that = this;
      const mask = this.$refs.mask;

      const startX = e.pageX - mask.offsetLeft;
      const scrollLeft = mask.scrollLeft;

      mask.onmousemove = (el) => {
        const ev = el || window.event;
        ev.preventDefault();
        let x = ev.pageX - mask.offsetLeft;
        let walk = (x - startX) * 2;
        if(walk !== 0){
          mask.scrollLeft = scrollLeft - walk;
        }
      };
      mask.onmouseup = () => {
        that.$refs.mask.onmousemove = null;
        that.$refs.mask.onmouseup = null;
      };
      mask.onmouseleave = () => {
        that.$refs.mask.onmousemove = null;
        that.$refs.mask.onmouseup = null;
        mask.onmouseleave = null;
      }
      if(e.preventDefault){
        e.preventDefault();
      }else {
        return false;
      }
    },
  }
}
</script>

<style scoped>
.swiper-container::-webkit-scrollbar {
  display: none;
}
.swiper-container {
  /*width: 540px;*/
  width: inherit;
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.swiper-image-view {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.swiper-video {
  width: inherit;
  height: 340px;
  object-fit: cover;
}
.swiper-video-item {
  width: 540px;
  aspect-ratio: 16 / 9;

  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  object-fit: cover;
}

.swiper-video-item::-webkit-media-controls-fullscreen-button {
  display: none;
}

.swiper-image {
  width: 170px;
  aspect-ratio: 9 / 16;
  border-radius: 8px;
  object-fit: cover;
}

@media only screen and (max-width: 499px){
  .swiper-video-item {
    width: 340px;
  }
  .swiper-image {
    width: 108px;
  }
}

</style>