<template>
  <div>
    <div>
      <div class="top-title">
        <div class="middle-size" style="display: flex;flex-direction: row;column-gap: 10px;position: fixed;background-color: #fafcfd;padding: 10px 0 10px;">
          <div class="game-detail-back">
            <img src="../../../static/icon/back.png" @click="$router.back()">
          </div>
          <div>
            游戏攻略
          </div>
        </div>
      </div>
      <div>
        <div class="base-flex-row game-detail-info-server gift-content-view" @click="toContentDetail(item.contentId)" v-for="item in contentList" :key="item.contentId">
          <div style="width: 80%;">
            <div class="base-hidde-text gift-name-text gift-info-width" >{{ item.contentName }}</div>
            <div class="sidebar-right-game-item-tag base-hidde-text gift-content gift-info-width">
              {{ item.contentDes }}
            </div>
          </div>
          <div>
            <svg t="1720073482704" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
              <path d="M457.116507 270.770913" fill="#262635"></path>
              <path d="M374.52751 822.6072c-6.500041 0-12.997012-2.478448-17.956977-7.437389-9.920954-9.91686-9.924024-25.999141-0.00614-35.920094l271.304056-271.394107c0.202615-0.201591 0.607844-0.603751 0.60989-1.397836 0.002047-0.589424-0.212848-1.104147-0.63752-1.529843L356.596116 233.806024c-9.920954-9.91686-9.925047-25.999141-0.008186-35.920094 9.915837-9.920954 25.999141-9.92607 35.920094-0.008186l271.279497 271.1567c10.04068 10.07445 15.528672 23.401989 15.489786 37.561477-0.037862 14.100136-5.561669 27.327392-15.552208 37.243229L392.489604 815.164694C387.530662 820.126706 381.028575 822.6072 374.52751 822.6072z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {request} from "@/api/request";

export default {
  name: "contentlist",
  data(){
    return {
      gameId: '',
      contentList: [],
    }
  },
  mounted() {
    this.gameId = this.$route.params.pathMatch;
    this.loadContent();
  },
  methods: {
    loadContent(){
      let options = {
        url: `/content/getContent/${this.gameId}`,
        method: 'post',
      }
      request(options).then(res => {
        if(res.data.success){
          this.contentList = res.data.data;
        }
      })
    },
    toContentDetail(contentId){
      this.$router.push(`/game/content/detail/${contentId}`);
    }
  }
}
</script>

<style scoped src="../../../static/css/game-detail.css" />
<style scoped src="../../../static/css/gift.css" />
<style scoped>

</style>