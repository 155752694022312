import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Vant from 'vant';
import 'vant/lib/index.css';
import "@/static/css/common.css";
Vue.use(Vant);
Vue.use(ElementUI);
Vue.config.productionTip = false

router.beforeEach(( to, from, next ) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
