import { render, staticRenderFns } from "./contentDetail.vue?vue&type=template&id=302750a5&scoped=true"
import script from "./contentDetail.vue?vue&type=script&lang=js"
export * from "./contentDetail.vue?vue&type=script&lang=js"
import style0 from "../../../static/css/game-detail.css?vue&type=style&index=0&id=302750a5&prod&scoped=true&lang=css&external"
import style1 from "../../../static/css/gift.css?vue&type=style&index=1&id=302750a5&prod&scoped=true&lang=css&external"
import style2 from "./contentDetail.vue?vue&type=style&index=2&id=302750a5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "302750a5",
  null
  
)

export default component.exports