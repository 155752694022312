<template>
  <div>
    <div class="download-bg">
      <div class="download-view">
        <van-image src="https://cdn.7li.club/image/jyqzxxpg/logo.png" width="100" height="100"/>
        <div style="width: 100%;display: flex;flex-direction: column;row-gap: 20px;">
          <van-button class="android-btn" round block :icon="androidImg" @click="download(1)">安卓下载</van-button>
          <van-button class="transparent-btn ios-btn"  round block :icon="iosImg" @click="download(2)">苹果下载</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "downloadPage",
  data(){
    return {
      androidImg: require("@/static/icon/android.png"),
      iosImg: require("@/static/icon/ios.png"),
    }
  },
  methods: {
    download(type){
      if(type === 1) {
        location.href = 'https://cdn.7li.club/jyqzxxpg/1/qiliyouxiapp.apk';
      }else if(type === 2){
        let a = 'https://apps.apple.com/cn/app/%E4%B8%83%E9%87%8C%E6%B8%B8%E6%88%8F/id1620672705';
        window.open(a)
      }
    }
  }
}
</script>

<style scoped>

.transparent-btn {
  background-color: transparent;
}
.ios-btn {
  color: #f0f2f4;
  font-weight: 700;
  font-size: 18px;
}
.android-btn {
  color: #73d8ba;
  font-weight: 700;
  font-size: 18px;
}

.download-bg {
  height: 100vh;
  background-image:linear-gradient(321deg, #4ac29a,#bdfff3);
}
.download-view {
  width: 440px;
  height: 550px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  
  transform: translateY(10%);
}

@media only screen and (max-width: 499px){
  .download-view {
    width: 320px;
  }
}
</style>