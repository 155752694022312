<template>
  <div>
    <div>
      <van-nav-bar
        title="关于我们"
        left-arrow
        left-text="返回"
        @click-left="onClickLeft"
        placeholder
        safe-area-inset-top />
      <van-image :src="require('@/static/logo/ql_logo.png')" style="width: 6rem;height: 6rem;display: block;margin: 0 auto;" />
      <div style="padding: 0 1.25rem;text-align:justify;text-indent: 2em">
        长沙七里互娱网络科技有限公司
        于2019年成立（简称“七里网络”）， 公司以"聚焦玩家，重拾快乐"为理念，
        依托七里网络自身强大的研发能力、丰富的运营经验和专业的服务能力三大核心竞争力。
        主营游戏领域的技术开发、技术转让、技术服务以及广告发布等，服务于各类大型网游“斗罗大陆”“怒火一刀”等知名H5，
        通过各大直播平台对新手玩家进行游戏指导与游戏攻略输出，同时本公司提供账号管理、充值、客服等服务，致力于网聚精品游戏的同时，
        打造轻松和谐的在线娱乐王国。
      </div>
      <div v-if="false" style="font-size: 12px;color: #8b8b8b;text-align: center;width: 100vw;margin-top: 1rem;">
        <a target="_blank" href="https://game.7li.cn/static/sdk/user.html" style="color: #8b8b8b;text-underline: none;">《用户协议》</a>
        <span>&</span>
        <a target="_blank" href="https://game.7li.cn/static/download/privacy.html" style="color: #8b8b8b;text-underline: none;">《隐私政策》</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about",
  data(){
    return {}
  },
  methods: {
    onClickLeft(){
      this.$router.back();
    }
  }
}
</script>

<style scoped src="../../../../static/css/setting.css"></style>
<style scoped>
</style>