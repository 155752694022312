<template>
  <div class="sdk-page">
    <div class="sdk-nav-bar">
      <div @click="back" style="width: 40px;height: 40px;display: flex;align-items: center;justify-content: center; position: absolute;">
        <van-icon name="arrow-left" size="20" color="#888888"/>
      </div>
      <div style="margin-left:40px;font-size: 16px;">
        绑定微信
      </div>
      <van-icon @click="close" name="cross" color="#d0d0d0" size="20" class="close-x"/>
    </div>
    
    <div>
      <div class="bind-wx-view" v-if="isBindWx">
        <div class="bind-wx-explain" style="background-color: #fddad1;color: #f3441a;">
          <span>解绑微信将无法继续领取福利红包，请谨慎解绑。为了帐号安全，如果帐号绑定了手机号码，还需提供手机验证码</span>
        </div>
        <div style="display: flex;align-items: center;margin-top: 15px;">
          <img :src="headImg" style="width: 50px;height: 50px;border-radius: 3px;">
          <span class="bind-code-view">{{ code }}</span>
        </div>

        <div style="margin-top: 15px" v-if="!showCodeForUnbindWx">
          <span>已绑定当前微信，如需解绑，请点击下方按钮</span>

          <van-button type="info" block native-type="submit" :loading="showLoading"
                      style="height: 35px;font-weight: bold;width: 120px;margin-top: 20px;"
                      loading-text="提交中"  @click="unbindWx"
                      color="#48c9b0">
            解绑微信
          </van-button>
        </div>
        <div v-else style="margin-top: 20px;color: #888888">
          <span>本次操作需要身份认证，验证码已发送至您的手机{{ bindPhone }}</span>
          <div style="display: flex;align-items: center;justify-content: flex-start; margin-top: 20px">
            <div class="input-view" style="margin-top: 0;width: 130px;">
              <van-field type="digit" maxlength="6" placeholder="请输入验证码" class="common-input" v-model="unbindCode" />
            </div>

            <van-button type="info" block native-type="submit" :loading="showLoading"
                        style="height: 35px;font-weight: bold;width: 110px;margin-left: 15px;"
                        loading-text="提交中"  @click="unbindWxWithCode"
                        color="#EE3F14">
              确认解绑
            </van-button>
          </div>
        </div>
      </div>
      
      <div v-else class="bind-wx-view">
        <div class="bind-wx-explain" style="color: #09bd51;background-color: #cef2dc;">
          <div>微信绑定流程：</div>
          <div>1. 关注公众号：爽玩网络，在公众号聊天框底部菜单：活动福利 -> 绑定账号</div>
          <div>2. 在页面的输入框填写复制好的绑定码，点击绑定按钮</div>
        </div>
        <div>
          <span style="color: #666666">绑定码</span>
          <span class="bind-code-view">{{ code }}</span>
        </div>
        <div style="color: #666666;font-size: 11px;">
          绑定码已复制到剪贴板，去微信绑定可直接粘贴使用。
        </div>
        <div>
          <van-button type="info" native-type="submit" block :loading="showLoading"
                      style="height: 35px;font-weight: bold;width: 50%;margin: 5px auto 0;"
                      loading-text="正在拉起微信"  @click="openWx"
                      color="#48c9b0">
            去微信绑定
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sdkNotice from "@/api/sdk-notice";
import {request} from "@/api/request";

export default {
  name: "SdkWeixin",
  data(){
    return {
      showLoading: false,
      isBindWx: false,
      code: '',
      headImg: '',
      from: '',
      loadFinished: false,
      unbindWxSendTime: 0,
      showCodeForUnbindWx: false,
      bindPhone: '',
      unbindCode: '',
    }
  },
  mounted() {
    this.from = localStorage.getItem('from');
    this.initData();
  },
  methods: {
    initData() {
      this.showLoading = true;

      let options = {
        url:"/sdk/h5/bindWx",
        data: {}
      };

      request(options).then(res => {
        this.showLoading = false;
        if (res.data.success) {
          this.isBindWx = res.data.data.bindStatus === 2;
          this.code = res.data.data.bindInfo;
          this.headImg = res.data.data.headImg;
          if (!this.isBindWx) {
            sdkNotice.copy(this.code)
          }

          this.loadFinished = true;
        }
        else {
          this.$toast(res.data.error.message);
        }
      });
    },
    back() {
      this.$router.back()
    },
    close(){
      sdkNotice.close();
    },
    unbindWx() {
      this.$dialog.confirm({
        message: '解绑微信后无法参与各种福利活动，确认要解绑吗?',
        messageAlign: 'left'
      })
          .then(() => {
            this.checkHasBindPhone();
          })
          .catch(() => {
          });
    },
    checkHasBindPhone() {
      this.bindPhone = localStorage.getItem('phone');
      if(!!this.bindPhone) {
        let now = new Date().getTime();
        let interval = (now - this.unbindWxSendTime) /1000;
        if (interval < 60) {
          this.$toast('操作太频繁，请在' + (60 - interval) + '秒后重试');
          return ;
        }

        this.showLoading = true;
        let options = {
          url:"/sdk/h5/sendSms",
          data: {
            type: 6
          }
        };
        request(options).then(res => {
          this.showLoading = false;
          if (res.data.success) {
            this.unbindWxSendTime = now;
            this.showCodeForUnbindWx = true;
          }
          else {
            this.$toast(res.data.error.message);
          }
        });
      }
      else {
        this.doUnbindWx();
      }
    },
    unbindWxWithCode() {
      if (!/^\d{6}$/.test(this.unbindCode)) {
        this.$toast("请输入6位数字验证码");
        return ;
      }
      this.doUnbindWx();
    },
    doUnbindWx() {
      this.showLoading = true;
      let options = {
        url:"/sdk/h5/unBindWx",
        data: {
          checkCode: this.unbindCode
        }
      };
      request(options).then(res => {
        this.showLoading = false;
        if (res.data.success) {
          this.$toast('微信解绑成功');
          this.back();
        }
        else {
          this.$toast(res.data.error.message);
        }
      });
    },
    openWx() {
      this.showLoading = true;
      setTimeout(() => {
        this.showLoading = false;
      }, 2000);
      let url = 'weixin://';
      sdkNotice.startIntent(url)
    }
  }
}
</script>

<style scoped>
.bind-code-view {
  margin-left: 20px;
  color: #343434;
  background-color: #efefef;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: 700;
  font-family: Arial;
}
.bind-wx-view {
  margin: 8px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: calc(100vh - 60px);
  overflow: hidden;
  overflow-y: auto;
}
.bind-wx-explain {
  padding: 10px;
  text-align: justify;
  font-size: 12px;
  border-radius: 8px;
}
</style>