<template>
  <div class="t-container flex">
    <header class="t-header">
      <div class="t-header__container">
        <div>
          <div class="t-header__nav-item">
            <router-link to="/home" class="t-header__link">
<!--              <img src="../../static/logo/ql_logo.png" width="50" height="50" alt="" />-->
              <img src="../../static/logo/sw-logo-t.png" width="50" height="50" alt="" />
            </router-link>
          </div>

          <div v-for="item in navList" :key="item.label" class="t-header__nav-item">
            <router-link :to="item.path" class="t-header__link" :class="{'t-header__link-active': item.path === $route.path}">
              <img :src="item.icon" class="t-header__link-img" alt="" v-show="item.path !== $route.path">
              <img :src="item.iconActive" class="t-header__link-img" alt="" v-show="item.path === $route.path">
              <span class="t-header__link--title">{{ item.label }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </header>
    <main class="t-main">
      <div style="width: 100%;height: calc(100vh - 20px);position: relative;">
        <div class="t-main__body">
          <div>
            <top-nav @click="onClickTopNav" v-if="showNav" :topic-list="topicList" class="t-top-nav"></top-nav>
            <search-box v-if="$route.meta.showNavigation" :search-swipe-data="searchSwipeData"></search-box>
          </div>
          <div>
            <router-view />
          </div>
        </div>
        <div class="mobile__nav-bar" v-if="$route.meta.showNavigation">
          <van-tabbar v-model="active"
                      z-index="999"
                      fixed
                      placeholder
                      active-color="#85878a">
            <van-tabbar-item v-for="item in navList" :key="item.label" :to="item.path">
              <span>{{ item.label }}</span>
              <img
                  slot="icon"
                  slot-scope="props"
                  :src="props.active ? item.iconActive : item.icon"
              >
            </van-tabbar-item>
          </van-tabbar>
        </div>
      </div>
    </main>
    <aside class="t-aside">
      <div class="t-aside__container">
        <div>
          <div style="margin-top: 15px;">
            <div>
              <div class="t-aside__user flex">
                <img src="../../static/icon/user.png" class="t-aside__icon" alt="" @click="toUserPage">
              </div>
            </div>
            <div style="position: relative;top: 0;" ref="sidebarRight">
              <div class="sidebar-right-item1" >
                <div>
                  <div class="sidebar-right-text">热门游戏</div>
                </div>
                <div>
                  <div @click="toGameDetails(item.gameId)" v-for="item in hotGame" class="sidebar-right-game-item">
                    <img :src="item.icon" width="60" height="60" alt="">
                    <div>
                      <div class="sidebar-right-game-item-title">{{ item.gameName }}</div>
                      <div v-if="!!item.tag">
                        <span class="sidebar-right-game-item-tag" v-for="(tag,index) in item.tag.split(',')" :key="index">{{ tag }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div @click="toTopPage" class="sidebar-right-item1-bottom">
                  查看更多
                </div>
              </div>
              <div class="sidebar-right-item1 sidebar-right-gap">
                <div class="base-padding" style="display: flex;flex-direction: row;column-gap: 20px;align-items: center">
                  <img src="../../static/logo/appQr.png" width="100" height="100" alt="" style="border-radius: 8px" />
                  <div>
                    <div style="font-weight: 700">扫码下载爽玩游戏APP</div>
                  </div>
                </div>
              </div>
              <div class="sidebar-right-item3 sidebar-right-gap">
                <div class="base-padding sidebar-right-item3-1" style="color: #75787b">
                  <div style="display: flex;flex-direction: row;column-gap: 10px;">
                    <span style="cursor: pointer" @click="$router.push('/about')">关于我们</span>
                    <span style="cursor: pointer" @click="$router.push('/user/agreement')">用户协议</span>
                    <span style="cursor: pointer" @click="$router.push('/privacy')">隐私政策</span>
                  </div>
                  <div style="font-size: 13px;">©2021 7li.club/ All Rights Resered. </div>
                </div>
                <div class="base-padding">
                  <div class="filing-view">
                    <img src="../../static/icon/icp.png" />
                    <a href="https://beian.miit.gov.cn/#/Integrated/index">湘ICP备20015231号-2</a>
                  </div>
                  <div class="filing-view" v-if="false">
                    <img src="../../static/icon/gongan.png" />
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004716">川公网安备51019002004716号</a>
                  </div>
                  <div>
                    <div class="health-game">
                      战略合作：4000818979按1转接
                    </div>
                  </div>
                </div>
                <div class="base-padding">
                  <div class="health-game">
                    <ul>
                      <li>抵制不良游戏，拒绝盗版游戏。</li>
                      <li>注意自我保护，谨防受骗上当。</li>
                      <li>适度游戏益脑，沉迷游戏伤身。</li>
                      <li>合理安排时间，享受健康生活。</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>

    <van-dialog v-model="showMenu" :showConfirmButton="false" class="dialog">
      <login v-if="showLogin"></login>
      <register v-if="showRegister"></register>
      <forget v-if="showForget"></forget>
    </van-dialog>
    
  </div>
</template>

<script>

import SearchBox from "@/components/searchBox.vue";
import {getHotGame, getHotSearch, getIndex, getSearch} from "@/api/request";
import TopNav from "@/components/topNav.vue";
import tools from "@/utils/Tools";
import login from "@/components/layout/login.vue";
import register from "@/components/layout/register.vue";
import forget from "@/components/layout/forget.vue";

export default {
  name: "indexTwo",
  components: {login,register,forget,TopNav, SearchBox},
  watch: {
    $route:{
      handler(val,oldval){
        this.setActive();
        this.checkPath();
        // const token = localStorage.getItem("token");
        // if(val.path === "/my" && !token){
        //   this.$router.replace("/login");
        // }
      },
    }
  },
  data() {
    return {
      active: '',
      navList: [
        {label: '首页',path: "/home",icon: require("@/static/icon/home.png"),iconActive: require("@/static/icon/home-active.png")},
        {label: '游戏',path: "/top",icon: require("@/static/icon/ranking.png"),iconActive: require("@/static/icon/ranking-active.png")},
        {label: '我的',path: "/my",icon: require("@/static/icon/my.png"),iconActive: require("@/static/icon/my-active.png")},
      ],
      searchSwipeData: [],
      hotGame: [],
      showNav: false,
      topicList: [
        {typeId: 1,typeName: '首页',path: '/home'},
        {typeId: 2,typeName: '热游',path: '/hot'},
      ],
      showMenu: false,
      showLogin: true,
      showRegister: false,
      showForget: false,
    }
  },
  mounted() {
    this.setActive();
    this.checkPath();
    this.getSearchSwipe();
    this.getHot();

    this.getIndex();
    this.getSearchData();
  },
  methods: {
    setActive(){
      switch( window.location.pathname){
        case '/home' :  this.active = 0;break;
        case '/top' :  this.active = 1;break;
        case '/my' :  this.active = 2;break;
        default : this.active = 0;
      }
    },
    getHot(){
      getHotGame().then(res => {
        if(res.data.success){
          this.hotGame = res.data.data;
        }
      })
    },
    getSearchSwipe(){
      setTimeout(() => {
        const ss = JSON.parse(sessionStorage.getItem("search_swiper"));
        if(ss){
          this.searchSwipeData = ss;
        }
      },10)
    },
    checkPath(){
      let arr = ['/home','/hot'];
      this.showNav = tools.isMobile() && arr.includes(this.$route.path);
    },
    onClickTopNav(e){
      let find = this.topicList.find(f => f.typeId === e);
      if(find){
        this.$router.push(find.path);
      }
    },
    toUserPage(){
      let token = localStorage.getItem("token");
      if(!token){
        // this.$router.push("/login");
        this.openLogin();
        this.openMenu();
      }else {
        this.$router.push("/my");
      }
    },
    toGameDetails(gameId){
      this.$router.push(`/game/details/${gameId}`);
    },
    toTopPage(){
      this.$router.push('/top');
    },
    getIndex(){
      getIndex().then(res => {

        let topics = [];
        res.data.data.typeList.forEach(item => {
          topics.push({
            typeId: item.typeId,
            typeName: item.typeName,
          })
        })
        sessionStorage.setItem("topic",JSON.stringify(topics));
      })
    },
    getSearchData(){
      getSearch().then(res => {
        if(res.data.success){
          sessionStorage.setItem("search_swiper",JSON.stringify(res.data.data));
        }
      })
    },
    
    
    openMenu(){
      this.showMenu = true;
    },
    closeMenu(){
      this.showMenu = false;
    },
    openLogin(){
      this.showLogin = true;
      this.closeRegister();
      this.closeForget();
    },
    closeLogin(){
      this.showLogin = false;
    },
    openRegister(){
      this.showRegister = true;
      this.closeLogin();
      this.closeForget();
    },
    closeRegister(){
      this.showRegister = false;
    },
    openForget(){
      this.showForget = true;
      this.closeLogin();
      this.closeRegister();
    },
    closeForget(){
      this.showForget = false;
    },
  }
}
</script>

<style scoped>
.flex {
  display: flex;
}
.t-container {
  max-width: 1496px;
  position: relative;
  margin: 0 auto;
  justify-content: center;
  padding: 0 16px;
}


.t-header__container:hover {
  overflow-y: auto;  
}
.t-header__container {
  position: fixed;
  /*position: -webkit-sticky;*/
  top: 0;
  width: inherit;
  height: 100vh;
  margin-top: 15px;
}
.t-header {
  width:  230px;
  height: 100vh;
  position: relative;
  z-index: 200;
}
.t-header__nav-item {
  height: 50px;
}
.t-header__link {
  height: 100%;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  
  font-weight: 700;
  color: #75787b;
    
  border-radius: 99px;
  padding: 0 15px;
}
.t-header__link-active {
    background-color: #f0f2f4;
}

.t-header__link-img{
  width: 30px;
  height: 30px;
}
.t-header__link--title {
  margin-left: 10px;
  font-weight: 700;
}


.t-main {
  width:  600px;
  /*background: linear-gradient(#67f695, #8c40ff);*/
  padding-bottom: 15px;
}
.t-main__body {
  min-height: 100vh;
  margin: 0 30px 20px;
}

.t-aside {
  width:  350px;
  height: 100vh;
  /*background-color: #ed7840;*/
}
.t-aside__container {
  /*position: sticky;*/
  /*position: -webkit-sticky;*/
  position: fixed;
  top: 0;
  z-index: 200;
  padding-bottom: 130px;
}
.t-aside__user {
  height: 50px;
  justify-content: flex-end;
}
.t-aside__icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.mobile__nav-bar {
  height: 50px;
  display: none;
}

.t-top-nav {
  display: none;
}

.dialog {
  width: 500px;
  transition: width .4s ease-in-out;
}

@media only screen and (max-width: 499px) {
  .t-container {
    padding: 0 8px;
  }
  .t-main  {
    width: 100%;
  }
  .t-main__body {
    margin: 0 10px;
  }
  
 .t-header {
   display: none;
 }
  .mobile__nav-bar {
    display: block;
  }
  .t-top-nav {
    display: block;
  }
}

@media only screen and (max-width: 696px) {
  .t-main {
    width: 0;
    flex: 1;
  }
  .dialog {
    width: 340px;
  }
}

@media only screen and (max-width: 1001px) {
  .t-aside {
    display: none;
  }
  .t-aside__container {
    top: -100px;
  }
}

@media only screen and (max-width: 1218px){
  .t-header {
    width: 60px;
  }
  .t-header__link {
    justify-content: center;
  }
  .t-header__link--title {
    display: none;
  }
  .t-aside__container {
    top: 0;
  }
}


</style>