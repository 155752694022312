<template>
  <div>
    <div>
      <div class="my__user-info">
        <div class="top__setting">
          <van-icon v-show="isLogin" name="setting-o" color="#fff" size="1.25em" @click="toSettingPage" style="cursor: pointer;"/>
        </div>
        <div>
          <div class="my__user-info-item">
            <div>
              <img src="../../../static/icon/user.png" width="60" height="60" alt="" style="border-radius: 99px;" />
            </div>
            <div v-show="isLogin" class="my__user-info-title">
              <div>{{ user.nickname }}</div>
              <div style="font-size: 12px;color: #409EFF" @click="copyID(user.userId)">
                <span>ID:&nbsp;</span>
                <span>{{ user.userId }}</span>
              </div>
            </div>
            <div style="margin-left: 15px;" v-show="!isLogin">
              <div @click="loginPage">登录 / 注册</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="false">
        <div class="service">
          <div @click="toKeFu">
            <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="22780" width="40" height="40">
              <path d="M510.097674 189.494819c-134.373407 0-243.281839 106.623389-243.281839 238.182704 0 131.541919 108.908432 238.179634 243.281839 238.179634 134.3775 0 243.285932-106.636692 243.285932-238.179634C753.383606 296.117185 644.475174 189.494819 510.097674 189.494819L510.097674 189.494819zM510.097674 189.494819" fill="#9ad1aa" p-id="22781"></path>
              <path d="M229.17761 519.102768 229.17761 299.209616c-3.079128 0-6.132674 0.125867-9.148357 0.366344C269.882684 191.349049 380.918569 115.974361 510.135536 115.974361c129.21185 0 240.253876 75.374688 290.103214 183.600575-3.016707-0.240477-6.066159-0.366344-9.148357-0.366344l0 219.894176c62.052265 0 112.390743-49.231261 112.390743-109.941971 0-41.235147-23.219841-77.163427-57.552787-95.982028-48.314379-136.089491-180.214455-233.837746-335.792812-233.837746-155.577335 0-287.469224 97.739045-335.788719 233.824443-34.344203 18.815531-57.555857 54.750975-57.555857 95.990215C116.79096 469.871507 167.090553 519.102768 229.17761 519.102768L229.17761 519.102768zM229.17761 519.102768" fill="#9ad1aa" p-id="22782"></path>
              <path d="M752.869906 645.160789c-63.570851 58.08593-148.919704 93.65196-242.772232 93.65196-93.676519 0-178.881087-35.435047-242.415099-93.327572C153.974837 702.767811 79.32465 804.722878 79.32465 943.226349l864.503403 0C943.827029 804.465005 867.780029 702.385095 752.869906 645.160789L752.869906 645.160789zM752.869906 645.160789" fill="#9ad1aa" p-id="22783"></path>
            </svg>
            <div style="font-size: 12px;color: #75787b;">联系客服</div>
          </div>
        </div>
      </div>
      <div>
        <van-tabs ref="tabs" v-model="active" lazy-render animated swipeable background="#fafcfd" line-width="30px" @rendered="tabRendered">
          <van-tab title="我的游戏" class="my-tab">
            <div v-if="myGameList.length !== 0">
              <div class="game-info-item" v-for="item in myGameList" :key="item.gameId">
                <img :src="item.icon" width="60" height="60" alt="" />
                <div>
                  <div>{{ item.gameName }}</div>
                  <div v-if="!!item.tag">
                    <span class="sidebar-right-game-item-tag" v-for="(tag,index) in item.tag.split(',')" :key="index">{{ tag }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div>
                <img src="../../../static/icon/empty.png" width="350" height="300" />
              </div>
              <div style="color: #d5d5d5">还没有玩过任何游戏~~~</div>
              <div style="color: #9ad1aa;margin-top: 8px;cursor: pointer;" @click="$router.push('/top')">去玩一下</div>
            </div>
          </van-tab>
          <van-tab title="我的礼包" class="my-tab">
            <div v-if="myGiftList.length !== 0" class="gift-view">
              <div v-for="item in myGiftList" style="width: 100%;justify-content: space-between;" class="game-info-item">
                <div>
                  <img :src="item.imgUrl" width="50" height="50"/>
                </div>
                <div style="flex: 1;">
                  <div style="font-weight: 700" class="base-hidde-text base-show-title">{{ item.giftName }}</div>
                  <div style="font-size: 14px;color: #666666;" class="base-hidde-text base-show-title">{{ item.giftDetail }}</div>
                  <div style="font-size: 12px;color: #666666;" class="base-hidde-text base-show-title">{{ item.gameName }}</div>
                </div>
                <div>
                  <button class="get-gift" style="padding: 5px 8px;" @click="copyID(item.giftCode)">复制</button>
                </div>
              </div>
            </div>
            <div v-else style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div>
                <img src="../../../static/icon/empty.png" width="350" height="300" />
              </div>
              <div style="color: #d5d5d5">还未领取过礼包~~~</div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {getMyGame, getMyGift, logout} from "@/api/request";
import {StorageEmitter} from "@/utils/StorageEmitter";

export default {
  name: "mobileMy",
  data(){
    return {
      active: 0,
      user: {nickname: '',userId: ''},
      isLogin: false,
      myGameList: [],
      myGiftList: [],
    }
  },
  mounted() {
    this.checkUser();
    window.addEventListener('resize', () => {})
    StorageEmitter.on("login",() => this.checkUser());
  },
  methods: {
    logout(){
      logout().then(res => {
        localStorage.removeItem("token");
        localStorage.removeItem("user_info");
        this.$router.push('/home');
      })
    },
    loadMyGame(){
      getMyGame().then(res => {
        this.myGameList = res.data.data;
      })
    },
    loadMyGift(){
      getMyGift().then(res => {
        this.myGiftList = res.data.data;
      })
    },
    tabRendered(name,title){
      let token = localStorage.getItem("token");
      if(!token){
        return;
      }
      if(title === '我的游戏'){
        this.loadMyGame();
      }else if(title === '我的礼包'){
        this.loadMyGift();  
      }
    },
    checkUser(){
      let user = localStorage.getItem("user_info");
      if(!this.isJSON(user)){
        localStorage.removeItem("user_info");
        localStorage.removeItem("token");
        return;
      }
      let userinfo = JSON.parse(user);
      if(!userinfo){
        return;
      }
      this.user.nickname = userinfo.nickName;
      this.user.userId = userinfo.userId;
      this.isLogin = !Object.values(this.user).includes('');
    },
    isJSON(str) {
      if (typeof str == 'string') {
        try {
          var obj = JSON.parse(str);
          if(typeof obj == 'object' && obj){
            return true;
          }else{
            return false;
          }

        } catch(e) {
          console.log('error：' + str + '!!!' + e);
          return false;
        }
      }
      console.log('It is not a string!');
      return false;
    },
    copyID(text){
      let input = document.createElement('input');
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      this.$toast("复制成功");
    },
    toSettingPage(){
      this.$router.push('/setting');
    },
    toKeFu(){
      this.$router.push('/kf')
    },
    loginPage(){
      // this.$router.push('/login')
      this.$parent.openLogin();
      this.$parent.openMenu();
    },
  }
}
</script>

<style scoped src="../../../static/css/gift.css" />
<style scoped>
/deep/ .van-tabs__line {
  background-color: #9ad1aa;
}
/deep/ .van-tab {
  flex: none;
  padding: 0 10px;
}
/deep/ .van-tab:nth-child(1) {
  padding-left: 20px;
}
.service {
  height: 90px;
  /*padding: 0 20px;*/
  margin: 20px 18px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  
  display: grid;
  grid-template-columns: repeat(4,23%);
  grid-gap: 10px;
  align-items: center;
  justify-items: center;
}
.my-tab {
  padding: 15px 0;
}
.game-info-item {
  margin-bottom: 10px;
  padding: 0 10px;
  height: 80px;
  border-radius: 8px;
  background-color: #f0f2f4;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

.my__user-info {
  background-color: #9ad1aa;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 8px;
}
.top__setting {
  display: flex;
  justify-content: flex-end;
}
.my__user-info-item {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
}
.my__user-info-title {
  padding-left: 15px;
}
.gift-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>