import axios from "@/api/axios";

export async function request (options) {
  return axios({
    url: options.url,
    method: options.method || 'post',
    data: options.data || {}
  })
}

export async function sdkRequest (options) {
  let gameId = localStorage.getItem("gameId");
  options.data.device = '';
  options.data.systemVersion = '';
  options.data.udid = '';
  options.data.gameId = gameId;
  options.data.sdkVersion = '';
  options.data.time = new Date().getTime();

  return axios({
    url: options.url,
    method: 'post',
    data: options.data
  })
}

export async function post (options) {
  options.data.gameId = localStorage.getItem("gameId");

  return axios({
    url: options.url,
    method: 'post',
    data: options.data
  })
}

export async function get (url) {
  return axios({
    url: url,
    method: 'get',
  })
}

export async function login(params = {}){
  return axios({
    url: `/web/login`,
    method: 'post',
    data: params
  })
}
export async function toRegister(params = {}){
  return axios({
    url: `/h5/register`,
    method: 'post',
    data: params
  })
}

export async function logout(){
  return axios({
    url: `/h5/logout`,
    method: "post",
    data: {}
  })
}

export async function toForget(params = {}){
  return axios({
    url: `/web/password/reset`,//Todo
    method: 'post',
    data: params,
  })
}

export async function sendVerifyCode(params = {}){
  return axios({
    url: `/h5/sendSms`,
    method: 'post',
    data: params
  })
}

export async function verifyCode(code,username){
  return axios({
    url: `/game/checkVerifyCode/${code}/${username}`,
    method: "post"
  })
}

export async function realNameAuth(params = {}){
  return axios({
    url: `/h5/personCheck`,
    method: "post",
    data: params
  })
}

export async function changePassword(params = {}){
  return axios({
    url: `/web/password/change`, //todo
    method: 'post',
    data: params
  })
}

export async function getRealName(){
  return axios({
    url: `/user/getRealName`,
    method: 'post',
  })
}

export async function getIndex(){
  return axios({
    url: `/app/gameIndex`,
    method: 'get'
  })
}

export async function getGameByTopic(params = {}){
  return axios({
    url: `/game/getGameByTopic`,
    method: "post",
    data: params
  })
}

export async function getMyGame(){
  return axios({
    url: `/game/getMyGame`,
    method: 'post'
  })
}

export async function getMyGift(){
  return axios({
    url: `/gift/getMyGift`,
    method: 'post'
  })
}

export async function getGameDetail(gameId){
  return axios({
    url: `/game/gameDetail?gameId=${gameId}`,
    method: 'get'
  })
}

export async function getSearch(){
  return axios({
    url: `/game/getSearchData`,
    method: 'post'
  })
}

export async function getHotSearch(){
  return axios({
    url: `/game/getHotSearch`,
    method: 'post'
  })
}

export async function queryGame(params = {}){
  return axios ({
    url: `/game/searchGame`,
    method: 'post',
    data: params
  })
}

export async function getHomeData(){
  return axios ({
    url: `/game/getHome`,
    method: 'post',
  })
}

export async function getHotGame(){
  return axios({
    url: `/game/getHotGame`,
    method: 'post'
  })
}

export async function queryGift(gameId = ""){
  return axios({
    url: `/gift/getGift/${gameId}`,
    method: 'post'
  })
}

export async function receiveGift(giftId = ""){
  return axios({
    url: `/gift/receiveGift/${giftId}`,
    method: 'post'
  })
}

export async function getContentDetail(contentId = ""){
  return axios({
    url: `/content/getContentDetail/${contentId}`,
    method: 'post'
  })
}