<template>
  <div>
    <div>
      <top-nav @click="onChangeActive" :topic-list="topicList" :active.sync="active"></top-nav>
    </div>
    <div style="height: 100%;padding-bottom: 20px;">
      <van-list
          ref="list"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :offset="150"
          :immediate-check="false"
          @load="onLoad"
      >
        <div class="top-game-view" v-for="(item,index) in gameData" :key="index" @click="toGameDetails(item.gameId)">
          <div class="top-game-view-item">
            <div>
              <img class="top-game-view-item__image" :src="item.icon" width="60" height="60" alt=""/>
            </div>
            <div style="flex: 1;">
              <div class="game__title text-hidden">{{ item.gameName }}</div>
              <div v-if="!!item.tag">
                <span class="sidebar-right-game-item-tag" v-for="(tag,index) in item.tag.split(',')" :key="index">{{ tag }}</span>
              </div>
            </div>
            <div v-if="item.discount" style="flex: 0 0 20%">
              <div style="width: 100%;height: 30px;line-height: 30px;text-align: center;color: #fff;border-radius:99px;background-color: #90e1ac;">{{ item.discount }}折</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import TopNav from "@/components/topNav.vue";
import {getGameByTopic, getHotSearch} from "@/api/request";

export default {
  name: "top",
  components: {TopNav},
  data(){
    return {
      active: 0,
      loading: false,
      finished: false,
      pageNo: 1,
      gameData: [],
      hotSearch: [],
      topicList: [],
      typeId: '-1',
    }
  },
  mounted() {
    this.loadData();
    this.getTopic();
  },
  methods: {
    onChangeActive(e){
      this.gameData = [];
      this.pageNo = 1;
      this.typeId = e;
      this.loadData();
    },
    loadData() {
      // this.active = this.active === 0 ? -1 : this.active;
      let params = {
        pageNo: this.pageNo,
        params: {
          typeId: this.typeId,
        }
      }
      getGameByTopic(params).then(res => {
        if(res.data.success){
          let _data = res.data.data.list;
          if(_data.length !== 0){
            this.pageNo++;
            this.loading = false;
            this.gameData = [...this.gameData,..._data];
            this.$refs.list.check();
          }
          if(_data.length === 0){
            this.finished = true;
          }
        }
      })
    },
    onLoad(){
      this.loadData();
    },
    getTopic(){
      if(sessionStorage.getItem("topic")){
        this.topicList = JSON.parse(sessionStorage.getItem("topic"));
      }
    },
    toGameDetails(gameId){
      this.$router.push(`/game/details/${gameId}`);
    },
  }
}
</script>

<style scoped>
.ranking-text {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #252525;
}
.top-game-view {
  /*display: flex;*/
  /*align-items: center;*/
  cursor: pointer;
  margin-top: 15px;
}
.top-game-view:first-child {
  margin-top: 5px;
}
.top-game-view-item {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  height: 80px;
}
.top-game-view-item__image {
  width: 80px;
  height: 80px;
}
.game__title {
  width: 220px;
  font-size: 16px;
}
.text-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar-right-game-item {
  padding: 0;
}

@media only screen and (max-width: 499px){
  .ranking-text {
    font-size: 16px;
  }
  .top-game-view-item__image {
    width: 50px;
    height: 50px;
  }
  .game__title{
    width: 140px !important;
    font-size: 14px;
  }
  .top-game-view-item {
    height: 60px;
  }
}

@media only screen and (max-width: 696px) {
  .game__title{
    width: 160px;
  }
}

</style>