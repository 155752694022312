<template>
  <div>
    <div>
      <div>
        <div v-for="item in videoData" class="t-video__container" @click="toGameDetails(item.gameId)">
          <div class="t-video__item">
            <video :src="item.videoUrl" 
                class="t-border-radius-top t-video"
                controls loop muted disablePictureInPicture controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"></video>
          </div>
          <div class="t-border-radius-bottom" style="width: 100%;height: 40px;background-color: #e1e1e1;display: flex;align-items: center">
            <img :src="item.icon" alt="" width="25" height="25" style="margin-left: 15px;border-radius: 999px"/>
            <span style="margin-left: 10px;font-size: 14px;color: #75787b;">{{ item.gameName }}</span>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px;">
        <div @click="toGameDetails(item.gameId)" class="sidebar-right-game-item" style="padding:10px 0;border-bottom: 1px solid #f0f2f4;" v-for="(item,index) in newList" :key="index">
          <div>
            <img class="game__icon" :src="item.icon" alt=""/>
          </div>
          <div>
            <div class="game__title">{{ item.gameName }}</div>
            <div v-if="!!item.tag">
              <span class="sidebar-right-game-item-tag" v-for="(tag,index) in item.tag.split(',')" :key="tag">{{ tag }}</span>
            </div>
            <div class="sidebar-right-game-item-tag" v-if="false">
              <span style="color: #ed7840;">今天 13:00</span>
              <span style="margin-left: 5px;">开服</span>
            </div>
          </div>
        </div>
      </div>
      
      <div class="download-float" v-if="showFloat" @click="toDownloadAPP">
        <img src="../../../static/logo/sw-logo-t.png" width="25" height="25"/>
        <span>下载APP</span>
      </div>
    </div>
  </div>
</template>

<script>
import {getHomeData} from "@/api/request";
import tools from "@/utils/Tools";

export default {
  name: "home",
  data(){
    return {
      playVideo: 0,
      playVideoListener: null,
      timer: null,
      videoData: [],
      newList: [],
      showFloat: false,
      
      channelId: '1',
    }
  },
  beforeRouteLeave(to,form,next){
    window.removeEventListener("scroll",this.playVideoListener);
    clearTimeout(this.timer);
    next();
  },
  mounted() {
    if(this.$route.query.channelId){
      this.channelId = this.$route.query.channelId;
    }
    localStorage.setItem("channel", this.channelId)

    this.showFloat = tools.isMobile();
    this.loadData();
    this.controlVideo();
  },
  methods: {
    toGameDetails(gameId){
      this.$router.push(`/game/details/${gameId}`);
    },
    loadData(){
      getHomeData().then(res => {
        if(res.data.success){
          this.videoData = res.data.data.gameVideoInfos;
          this.newList = res.data.data.newList;
        }
      })
    },
    controlVideo(){
      let videos = document.getElementsByTagName("video");

      this.timer = setTimeout(() => {
        videos[this.playVideo] && videos[this.playVideo].play();
      },500);

      this.playVideoListener = () => {
        if (videos[this.playVideo] && videos[this.playVideo].offsetTop && window.scrollY > videos[this.playVideo].offsetTop) {
          videos[this.playVideo].pause();
          this.playVideo++;
          if(this.playVideo >= videos.length){
            this.playVideo = videos.length - 1;
            return;
          }
          videos[this.playVideo].play();
        }else {
          if(this.playVideo <= 0) {
            this.playVideo = 0;
            return;
          }
          videos[this.playVideo].pause();
          this.playVideo--;
          videos[this.playVideo].play();
        }
      }

      window.addEventListener("scroll",this.playVideoListener)
    },
    toDownloadAPP(){
      this.$router.push("/download");
    },
  }
}
</script>

<style scoped>
.t-video__container {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  margin: 15px auto 0;
}
.t-video__container:first-child {
  margin: 5px auto;
}
.t-video__item {
  width: 100%;
  height: 300px;
}
.t-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.t-border-radius-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.t-border-radius-bottom {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.game__title {
  font-weight: 700;
}
.game__icon {
  width: 80px;
  height: 80px;
}

.download-float {
  display: none;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  position:fixed;
  left: calc(50% - 60px);
  bottom: 60px;
  background: linear-gradient(110deg, #90e1ac 80%, #e4ec73);
  padding: 5px 10px;
  border-radius: 99px;
  box-shadow: 0 0 .26667rem 0 #48c9b0;
  color: #fff;
  font-size: 14px;
}

@media only screen and (max-width: 499px) {
  .t-video__item {
    height: 200px;
  }
  
  .game__icon {
    width: 60px;
    height: 60px;
  }
  .download-float {
    display: flex;
  }
}

</style>