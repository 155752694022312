<template>
  <div>
    <div>
      <div>
        <div>
          <div ref="topView" style="height: 60px;position: relative;z-index: 200;">
            <div style="position: fixed;background-color: #fafcfd;">
              <div class="top__game-search">
                <div class="game-detail-back">
                  <img src="../../../static/icon/back.png" @click="$router.back()">
                </div>
                <div ref="topNav">
                  <top-nav :topic-list="topicList"></top-nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <!-- 内容1开始 -->
          <div>
            <div>
              <swiper v-if="!!videoUrl || imageData.length !== 0" :video-url="videoUrl" :image-list="imageData"/>
            </div>
            <div style="display: flex;flex-direction: column;row-gap: 15px;margin-top: 15px;">
              <!-- 游戏信息 开始-->
              <div class="game__info-box">
                <div class="game__info-box">
                  <div>
                    <img :src="gameDetailData.gameIcon" width="60" height="60" alt=""/>
                  </div>
                  <div style="padding-left: 16px;">
                    <div class="base-hidde-text">{{ gameDetailData.gameName }}</div>
                    <div v-if="!!gameDetailData.tag">
                      <span v-for="(tag,index) in gameDetailData.tag.split(',')" :key="index" class="sidebar-right-game-item-tag">{{ tag }}</span>
                    </div>
                  </div>
                </div>
                <div v-if="!!gameDetailData.discount">
                  <div class="game-discount">
                    <span>{{ gameDetailData.discount }}</span>
                    <span>折</span>
                  </div>
                </div>
              </div>
              <!-- 游戏信息 结束-->

              <!--游戏介绍 开始-->
              <div class="game-detail-info-view">
                <div class="game-detail-info-view-title">
                  游戏介绍
                </div>
                <div>
                  <div style="margin-top: 10px;font-size: 14px;text-align: justify;">
                    {{ gameDetailData.gameDesc }}
                  </div>
                </div>
              </div>
              <!--游戏介绍 结束-->

              <!-- 游戏礼包 开始 -->
              <div class="game-detail-info-view">
                <div class="game-detail-info-view-title">
                  游戏礼包
                </div>
                <div v-if="giftList.length !== 0">
                  <div class="base-flex-row game-detail-info-server game-detail-info-content-view" v-for="item in giftList" @click="toLookMore">
                    <div style="width:80%;">
                      <div class="base-hidde-text">{{ item.giftName }}</div>
                      <div class="sidebar-right-game-item-tag base-hidde-text">
                        {{ item.giftDetail }}
                      </div>
                    </div>
                    <div>
                      <svg t="1720073482704" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                        <path d="M457.116507 270.770913" fill="#262635"></path>
                        <path d="M374.52751 822.6072c-6.500041 0-12.997012-2.478448-17.956977-7.437389-9.920954-9.91686-9.924024-25.999141-0.00614-35.920094l271.304056-271.394107c0.202615-0.201591 0.607844-0.603751 0.60989-1.397836 0.002047-0.589424-0.212848-1.104147-0.63752-1.529843L356.596116 233.806024c-9.920954-9.91686-9.925047-25.999141-0.008186-35.920094 9.915837-9.920954 25.999141-9.92607 35.920094-0.008186l271.279497 271.1567c10.04068 10.07445 15.528672 23.401989 15.489786 37.561477-0.037862 14.100136-5.561669 27.327392-15.552208 37.243229L392.489604 815.164694C387.530662 820.126706 381.028575 822.6072 374.52751 822.6072z" />
                      </svg>
                    </div>
                  </div>
                  <div class="look-more" @click="toLookMore">查看更多</div>
                </div>
                <div class="no-text" v-else>
                  暂无游戏礼包
                </div>
              </div>
              <!-- 游戏礼包 结束 -->

              <!-- 开服信息 开始 -->
              <div class="game-detail-info-view" v-if="false">
                <div class="game-detail-info-view-title">
                  开服信息
                </div>
                <div v-if="true">
                  <div class="base-flex-row game-detail-info-server">
                    <div v-for="item in 6" class="game-detail-info-server-item">
                      <div style="font-size: 18px;">07-04 10:00</div>
                      <div style="font-size: 14px;color: #75787b;">天使999服</div>
                    </div>
                  </div>
                </div>
                <div class="no-text" v-else>
                  暂无开服信息
                </div>
              </div>
              <!-- 开服信息 结束 -->

              <div class="game-detail-info-view">
                <div class="game-detail-info-view-title">
                  游戏攻略
                </div>
                <div v-if="contentList.length !== 0">
                  <div class="base-flex-row game-detail-info-server game-detail-info-content-view" v-for="item in contentList" :key="item.contentId" @click="toContentDetail(item.contentId)">
                    <div style="width: 80%;">
                      <div class="base-hidde-text" style="width: 240px;">{{ item.contentName }}</div>
                      <div class="sidebar-right-game-item-tag base-hidde-text" style="width:240px;">
                        {{ item.contentDes }}
                      </div>
                    </div>
                    <div>
                      <svg t="1720073482704" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                        <path d="M457.116507 270.770913" fill="#262635"></path>
                        <path d="M374.52751 822.6072c-6.500041 0-12.997012-2.478448-17.956977-7.437389-9.920954-9.91686-9.924024-25.999141-0.00614-35.920094l271.304056-271.394107c0.202615-0.201591 0.607844-0.603751 0.60989-1.397836 0.002047-0.589424-0.212848-1.104147-0.63752-1.529843L356.596116 233.806024c-9.920954-9.91686-9.925047-25.999141-0.008186-35.920094 9.915837-9.920954 25.999141-9.92607 35.920094-0.008186l271.279497 271.1567c10.04068 10.07445 15.528672 23.401989 15.489786 37.561477-0.037862 14.100136-5.561669 27.327392-15.552208 37.243229L392.489604 815.164694C387.530662 820.126706 381.028575 822.6072 374.52751 822.6072z" />
                      </svg>
                    </div>
                  </div>
                  <div class="look-more" @click="toLookMoreContent">查看更多</div>
                </div>
                <div class="no-text" v-else>
                  暂无游戏攻略
                </div>
              </div>
            </div>
          </div>
          <!-- 内容1结束 -->
        </div>
        <div>
          <div style="height: 90px;">
            <div ref="bottomDownload" class="bottom__download-container">
              <div v-if="gameDetailData.playUrl"  @click="toPlay" class="bottom__play-game">在线玩</div>
              <div class="bottom__download" @click="downloadGame">
                <span>下载</span>
                <span v-if="gameDetailData.gameSize" style="font-size: 13px;">( {{ gameDetailData.gameSize }} )</span>
              </div>
            </div>
          </div>
        </div>

        <loading-animation :show-overlay="showOverlay"/>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from "@/components/topNav.vue";
import {getGameDetail} from "@/api/request";
import MySwiper from "@/components/MySwiper.vue";
import Swiper from "@/components/Swiper.vue";
import LoadingAnimation from "@/components/loadingAnimation.vue";
import {StorageEmitter} from "@/utils/StorageEmitter";

export default {
  name: "gameDetails",
  components: {LoadingAnimation, Swiper, MySwiper, TopNav},
  watch: {
    '$route.params.pathMatch': {
      immediate: true,
      handler() {
        this.gameId = this.$route.params.pathMatch;
        this.loadGame();
      },
    }
  },
  data() {
    return {
      topicList: [
        {typeId: 1,typeName: '详情'}
      ],
      videoUrl: '',
      imageData: [],
      gameDetailData: [],
      gameId: '',
      giftList: [],
      contentList: [],
      showOverlay: false,
      isLogin: false,
    }
  },
  mounted() {
    this.gameId = this.$route.params.pathMatch;
    this.isLogin = !!localStorage.getItem("token");
    StorageEmitter.on("login",() => this.isLogin = !!localStorage.getItem("token"));
    this.onResize();
    window.addEventListener('resize', this.onResize);
    // this.loadGame();
  },
  methods: {
    loadGame(){
      this.showOverlay = true;
      getGameDetail(this.gameId).then(res => {
        if(res.data.success){
          this.showOverlay = false;
          this.imageData = [];
          this.videoUrl = '';
          
          this.gameDetailData = res.data.data;
          if(this.gameDetailData.introVideo){
            this.videoUrl = this.gameDetailData.introVideo.url;
          }
          if(this.gameDetailData.introImageList.length !== 0){
            this.gameDetailData.introImageList.forEach(f => {
              this.imageData.push(f.url);
            })
          }
          if(this.gameDetailData.gifts.length !== 0){
            this.giftList = this.gameDetailData.gifts;
          }
          if(this.gameDetailData.contents.length !== 0){
            this.contentList = this.gameDetailData.contents;
          }
        }else {
          this.$toast(res.data.error.message);
        }
      })
    },
    toPlay(){
        if(!this.isLogin){
          this.$parent.openMenu();
          return;
        }
        let channelId = localStorage.getItem("channel");
        if(channelId === "1"){
          window.open(this.gameDetailData.playUrl,'_blank');
        }else {
          window.open(this.gameDetailData.playUrl + "/" + channelId,'_blank');
        }
    },
    downloadGame(){
      window.location.href = this.gameDetailData.downloadUrl;
    },
    toLookMore(){
      this.$router.push(`/game/gift/${this.gameId}`);
    },
    toLookMoreContent(){
      this.$router.push(`/content/${this.gameId}`);
    },
    toContentDetail(contentId){
      this.$router.push(`/game/content/detail/${contentId}`);
    },
    onResize(){
      const topView = this.$refs.topView;
      const topNav = this.$refs.topNav;
      const download = this.$refs.bottomDownload;
      if (topNav && topNav.style) {
        topNav.style.width = download.style.width = topView.clientWidth + "px";
      }
    },
  }
}
</script>

<style scoped src="../../../static/css/game-detail.css" />
<style scoped>
.game-detail-back {
  margin-right: 15px;
}
.game-detail-back img {
  width: 30px;
  height: 30px;
}
.top__game-search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bottom__download-container {
  position: fixed;
  bottom: 0;
  background-color: #fafcfd;
  height: 60px;
  padding-bottom: 20px;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  column-gap: 10px;
}
.bottom__play-game {
  flex: 1;
  height: 40px;
  line-height: 40px;
  color: #9ad1aa;
  background-color: #d7eddd;
  border-radius: 99px;
  cursor: pointer;
}
.bottom__download {
  flex: 1;
  height: 40px;
  line-height: 40px;
  background-color: #9ad1aa;
  border-radius: 99px;
  color: #fff;
  cursor: pointer;
}
.game__info-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>