<template>
  <div>
    <div class="c-container">
      <div class="c-login-head">
        <div class="flex flex-row flex-1 c-login-head__container">
          <div class="c-login-head__title">忘记密码</div>
          <div>
            <van-icon name="cross" class="pointer" :color="iconColor" @mouseenter="setIconColor(0.7)" @mouseleave="setIconColor(1)" @click="close"/>
          </div>
        </div>
      </div>

      <div class="c-login-body">
        <div class="flex flex-column">
          <div>
            <input v-model.trim="forgetParam.username" class="c-login-body__item-input" type="text" placeholder="请输入手机号" />
          </div>
          <div>
            <input v-model.trim="forgetParam.password" class="c-login-body__item-input" type="password" placeholder="请输入密码" />
          </div>
          <div>
            <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;column-gap:10px;">
              <input v-model="forgetParam.smsCode" class="c-login-body__item-input" style="width: calc(100% - 100px)" type="text" placeholder="请输入验证码" />
              <div class="c-send__verify" v-show="!showCountDown" @click="sendVerifyCode">发送验证码</div>
              <div ref="countDown" class="c-send__verify" v-show="showCountDown">{{ countDown }}</div>
            </div>
          </div>
          <div class="mt-15">
            <div class="flex" style="justify-content: space-between;align-items: center;">
              <div class="c-login-body__text-button" @click="toRegister">立即注册</div>
              <div class="c-login-body__text-button" @click="toLogin">去登陆</div>
            </div>
          </div>
          <div class="mt-15">
            <van-button @click="doForget" block color="#90e1ac" style="border-radius: 5px;">确认重置</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tools from "@/utils/Tools";
import Crypto from "@/utils/crypto";
import {sendVerifyCode, toForget, toRegister} from "@/api/request";

export default {
  name: "register",
  data(){
    return {
      consent: false,
      forgetParam: {
        username: '',
        password: '',
        smsCode: '',
      },
      showCountDown: false,
      countDown: 0,
      iconColor: 'rgba(13, 13, 13, 1)',
    }
  },
  methods: {
    close(){
      this.$parent.close();
    },
    toLogin(){
      this.$parent.$parent.openLogin();
    },
    toRegister(){
      this.$parent.$parent.openRegister();
    },
    setIconColor(alpha){
      this.iconColor = `rgba(13, 13, 13, ${alpha})`;
    },
    doForget(){
      if(!Tools.isPhone(this.forgetParam.username)){
        this.$toast.fail("手机号码无效，请正确填写手机号码")
      }else if(!Tools.isPassWord(this.forgetParam.password)){
        this.$toast.fail("密码为6-12位数字、字母、字符")
      }else if(!this.forgetParam.smsCode){
        this.$toast.fail("请输入验证码")
      }else {
        let params = {
          userName: this.forgetParam.username,
          newPassword: Crypto.encrypt(this.forgetParam.password),
          checkCode: this.forgetParam.smsCode,
        }
        toForget(params).then(res => {
          if(res.data.success){
            this.$toast('密码重置成功')
            this.forgetParam = {
              username: '',
              password: '',
              smsCode: '',
            }
            this.close();
          }else {
            this.$toast.fail(res.data.error.message)
          }
        })
      }
    },
    sendVerifyCode() {
      if(!Tools.isPhone(this.forgetParam.username)){
        this.$toast.fail("手机号码无效，请正确填写手机号码")
        return;
      }
      let params = {
        type: 2,
        phone: this.forgetParam.username
      }
      sendVerifyCode(params).then(res => {
        if (res.data.success) {
          let time = 120;
          let timer = setInterval(() => {
            this.showCountDown = true;
            this.countDown = time + "s";
            time--;
            if (time < 0) {
              this.showCountDown = false;
              clearInterval(timer);
            }
          }, 1000);
        } else {
          alert(res.data.error.message)
        }
      }).catch(() => {
      })
    },
  }
}
</script>

<style src="../../static/css/network-auth.css"></style>
<style scoped>

</style>